<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <h1>Datos del paciente</h1>
    <p class="bold">
      Consecutivo: {{ currentService }}
    </p>
    <p class="bold">
      Nombre:
    </p>
    <p> 
      {{ (currentPatientInfo.bennm1 ?? '') + ' ' + (currentPatientInfo.bennm2 ?? '') + ' ' + (currentPatientInfo.benap1 ?? '') + ' ' + (currentPatientInfo.benap2 ?? '') }}
    </p>
    <p class="bold">
      Télefono:
    </p>
    <p> 
      {{ currentPatientInfo.bentel ?? '' }}
    </p>
    <p class="bold">
      Documento de Identidad:
    </p>
    <p> 
      {{ currentPatientInfo.bendoc ?? '' }}
    </p>
    <p class="bold">
      Edad:
    </p>
    <p> 
      {{ currentPatientInfo.edad ?? '' }}
    </p>
    <p class="bold">
      Sexo:
    </p>
    <p> 
      {{ currentPatientInfo.bensex.toLowerCase() == 'f'? 'Femenino' : 'Masculino' }}
    </p>
    <div class="background-grey">
      <p class="bold">
        Tel solicitud de servicio:
      </p>
      <p> 
        {{ currentPatientInfo.reqtel ?? '' }}
      </p>
      <p class="bold">
        Persona que llama:
      </p>
      <p> 
        {{ currentPatientInfo.reqper ?? '' }}
      </p>
      <p class="bold">
        Dir Emergencia:
      </p>
      <p> 
        {{ currentPatientInfo.reqdir ?? '' }}
      </p>
    </div>
    <p class="bold">
      EPS:
    </p>
    <p> 
      {{ currentPatientInfo.epsnom ?? '' }}
    </p>
    <p class="bold">
      Signos y síntomas:
    </p>
    <p> 
      {{ currentPatientInfo.reqsig ?? '' }}
    </p>
    <div class="background-grey">
      <p class="bold between">
        Código de valoración: 
        <v-chip
          text-color="white"
          class="ma-2"
          :style="{ background: color(currentPatientInfo.reqcda) }"
        >
          {{ currentPatientInfo.reqcda | triaje }}
        </v-chip>
      </p>
      <p> 
        {{ currentPatientInfo.reqtel ?? '' }}
      </p>
      <p class="bold">
        Antecedentes:
      </p>
      <p> 
        {{ currentPatientInfo.bentxt ?? '' }}
      </p>
      <p class="bold">
        Notas operador:
      </p>
      <p> 
        {{ currentPatientInfo.reqtxo ?? '' }}
      </p>
      <p class="bold">
        Notas Servicio:
      </p>
      <p> 
        {{ currentPatientInfo.reqtxt ?? '' }}
      </p>
      <p class="bold">
        Hora Llamada:
      </p>
      <p> 
        {{ currentPatientInfo.reqhor ?? '' }}
      </p>
      <p class="bold">
        Salbutamol:
      </p>
      <p> 
        {{ currentPatientInfo.salbutamol ?? 'PREGUTNAR CAMPO' }}
      </p>
      <p class="bold">
        Consulta Anterior:
      </p>
      <p>
        {{ currentPatientInfo.ult_diag ?? '' }} ({{ currentPatientInfo.ult_fecha ?? '' }} {{ currentPatientInfo.ult_hora ?? '' }})
        <br> 
        Traslado: {{ calcUltimaConsulta(currentPatientInfo.ult_traslado) }}
      </p>
      <div class="buttons px-4 d-flex flex-column">
        <v-btn
          block
          elevation="2"
          color="#d6d8d7"
        >
        HISTORIAS CLÍNICAS
        </v-btn>
        <v-btn
          block
          elevation="2"
          :color="!currentPatientInfo.REQHLL? 'yellow accent-2' :''"
          :class="{ transparent: currentPatientInfo.REQHLL, 'text-white': !currentPatientInfo.REQHLL }"
          @click="notificarHoraLlegada"
        >
        NOTIFICAR HORA DE LLEGADA
        </v-btn>
        <v-btn
          block
          elevation="2"
          color="#4451b4"
          style="color: white;"
          class="mb-10"
          @click="iniciarHistoriaClinica"
        >
        CREAR HISTORIA CLÍNICA
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import currentService from "@/modules/currentService";
import { mapState } from "vuex";
import sweetAlert from "@/modules/sweetAlert";
export default {
  name: "DatosPaciente",
  created() {
    const patientInfo = this.$store.state.currentPatientInfo;
    console.log(patientInfo);
    console.log(this.$store.state.currentPatient);
    currentService.getPatientData(this.$store.state.currentService).then(() => {
      console.log(this.$store.state);
    });
  },
  computed: mapState({
    currentPatientInfo: (state) => state.currentPatientInfo,
    currentService: (state) => state.currentService
  }),
  methods: {
    color(char) {
      const newChar = char.toLowerCase();
      switch (newChar) {
        case "a":
          return "#FFEB3B";
        case "v":
          return "#4CAF50";
        case "r":
          return "#F44336";
        case "p":
          return "#03A9F4";
        default:
          break;
      }
    },
    calcUltimaConsulta(consulta) {
      console.log(consulta);
      if(!consulta) { return 'No Aplica'; }
      const newConsulta = Number(consulta);
      switch (newConsulta) {
        case 30:
          return 'Si'
        case 40:
          return 'Si'
        case 10:
          return 'No'
        case 20:
          return 'No'
        case 41:
          return 'No'
        default:
          return 'No Aplica'
      }
    },
    notificarHoraLlegada(){
      if(this.currentPatientInfo.REQHLL) {
        sweetAlert.fire({
          title: "Hora Registrada Previamente",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
        });
        return;
      }
      currentService.registrarHoraLlegada(this.$store.state.currentService).then((response) => {
        const tempPatientInfo = JSON.parse(JSON.stringify(this.currentPatientInfo));
        tempPatientInfo.REQHLL = new Date().toLocaleString();
        this.$store.commit("patientInfo", tempPatientInfo);
        if(response) {
          sweetAlert.fire({
            title: "Hora Registrada!",
            icon: "success",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            timer: 2000,
            position: "center",
          });
        } else {
          sweetAlert.fire({
            title: "Hora Registrada Previamente",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            timer: 2000,
            position: "center",
          });
        }
      }).catch(() => {
        sweetAlert.fire({
          title: "Ups algo malo sucedio, por favor contacte al administrador",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
        });
      });
    },
    iniciarHistoriaClinica(){
      if(this.currentPatientInfo.REQHLL) {
        sweetAlert
          .fire({
            title: "Desea Crear Historia Clinica",
            showCancelButton: true,
            confirmButtonText: `Aceptar`,
            cancelButtonText: `Cancelar`,
            reverseButtons: true,
          })
          .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.$store.commit("initHistoriaClinica", this.currentService);
              const hora = await currentService.getHourServer();
              this.$store.commit("setKeyToHistory", {
                 consecutivo: this.currentService,
                 validate: true,
                 key: 'fecha_inicio',
                 value: hora,
              });
              this.$router.push({
                name: "ResumenConsulta",
              });
            }
          });
        
      }else {
        sweetAlert.fire("Aun no has registrado la hora", "", "error");
      }
      

    }
  }
};
</script>
<style lang="scss" scoped>
  h1 {
    text-align: center;
  }
  p {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
  .bold {
    font-weight: bold;
  }
  .between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
  }
  .background-grey {
    background: #efefef;
  }
  .buttons {
    gap: 1rem;
  }
  .transparent {
    background: transparent;
  }
  .text-white {
    color: white;
  }
</style>